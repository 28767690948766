import React from 'react';

import Grid from '@mui/material/Grid';

import Page from '../components/Page';
import Section from '../components/Section';
import Typography from '../components/Typography';
import Card from '../components/Card';
import Quote from '../components/Quote';
import FeatureSlider from '../components/FeatureSlider';

import graphicStudent from '../images/graphic_student.svg';
import innowellAssess from '../images/real_time.png';
import innowellInsight from '../images/insights.png';
import innowellContent from '../images/content.png';
import innowellHabit from '../images/habit.png';

import shield from '../images/icon-shield.svg';
import microscope from '../images/icon-microscope.svg';
import user from '../images/icon-user.svg';
import graph from '../images/icon-graph.svg';

const featureSlides = [
  {
    image: innowellAssess,
    title:'Real-time Assessments',
    content:'Clinical tools and instruments, carefully designed along with mental health experts at the Brain and Mind centre give live, meaningful insights into a user’s mental health care and pinpoint key focus areas.',
  },
  {
    image: innowellInsight,
    title:'Actionable Insights',
    content:'Individual privacy is protected at every level while giving your organisation deep, actionable insights into the recommended active tools to engage, support, and empower your students towards better mental health support.',
  },
  {
    image: innowellContent,
    title:'People-First Content',
    content:'A rich library of recommended articles, podcasts, activities, and more individualised for each user and designed to grow healthier self-management and understanding of personal mental health care.',
  },
  {
    image: innowellHabit,
    title:'Proactive Habit Tools ',
    content:'Focus areas allow users to better self-manage mental health, and paired with habit-forming activities, help grow healthier behaviour loops.',
  },
]

const YourStudents = () => {

  return (
    <Page title='Your Students'>
      {/* Hero */}
      <Section>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='Graphic for Students' src={graphicStudent}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='hero' preset={1}>Healthy minds, healthy students</Typography>
            <Typography type='paragraph' preset={2}>
              Research-based and people-first. Innowell facilitates real, actionable insights into the mental health posture of your student body, and promotes healthier, sustainable habits for individuals and a 'mental health first' academic culture.
            </Typography>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Typography type='heading' preset={2}>The BIG problem (that we're working to fix)</Typography>
        <Typography type='paragraph' preset={2}>
          1 in every 2 Australians will suffer from ill mental health at some stage in their life, and the likelihood of experiencing high levels of psychological distress more than doubles the younger you are.
        </Typography>
        <Typography type='paragraph' preset={2}>
          To make matters worse the average wait time to see a qualified mental health professional is over 14 months. The sad news? Only half of those who need it, will get it – and when they do, it’s unlikely the first specialist they meet will be the right one for their care needs.
        </Typography>
        <Typography type='paragraph' preset={2}>
          Mental health is no longer a secondary consideration for our students and youth. Mental health care is stewardship of our future.
        </Typography>
        <Typography type='heading' preset={2}>Your Innowell Solution</Typography>

        <ul>
          <li>
            <Typography type='paragraph' preset={2}>Science-backed tools and pathways that focus on reflection, self-management, and reshaping behaviour loops.</Typography>
          </li>
          <li>
            <Typography type='paragraph' preset={2}>Growing a 'mental health first' culture that doesn’t only improve quality-of-life for students but works proactively to strengthen community wellbeing and happiness. </Typography>
          </li>

          <li>
            <Typography type='paragraph' preset={2}>Proactive, not reactive, tools that support all areas of student wellbeing, through seven domains of mental health. </Typography>
          </li>

          <li>
            <Typography type='paragraph' preset={2}>Lead a mental health first culture and help your people be well, with Innowell. </Typography>
          </li>
        </ul>
      </Section>

      {/* Quote */}
      <Section theme='mint'>
        <Quote content='Our students are our future. The care of their mental wellbeing is the stewardship of that future'/>
      </Section>

      {/* Icon Grid */}
      <Section>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={graph} alt='Insight Driven icon'/>
              <Typography type='heading' preset={3}>Insight Driven</Typography>
              <Typography type='paragraph' preset={2}>Real-time, configurable analysis and deep analytics that give you control over meaningful, actionable reporting.</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={user} alt='Student Focused icon'/>
              <Typography type='heading' preset={3}>Student Focused</Typography>
              <Typography type='paragraph' preset={2}>Tools backed by real science and pathways tailored to individual student needs. </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={shield} alt='Privacy First icon'/>
              <Typography type='heading' preset={3}>Privacy First</Typography>
              <Typography type='paragraph' preset={2}>We never share data without permission from users. </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={microscope} alt='Evidence Backed icon'/>
              <Typography type='heading' preset={3}>Evidence Backed</Typography>
              <Typography type='paragraph' preset={2}>Over 10 years of scientific research and over a dozen research trials across the globe.</Typography>
            </Card>
          </Grid>
        </Grid>
      </Section>

      {/* The Clinical Platform */}
      <Section title='The Innowell Platform'>
        <FeatureSlider slides={featureSlides}/>
      </Section>

    </Page>
  )
};

export default YourStudents;
